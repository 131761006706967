import React from 'react'
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react'

type TextInputProps = {
  type: 'email' | 'password' | 'text'
  value: string
  placeholder: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  labelElement: React.ReactNode
}

export const CustomTextInput: React.FC<TextInputProps> = (props) => {
  return (
    <div className="mb-6 w-full bg-white rounded shadow-md flex">
      <label className="block px-3 py-2">{props.labelElement}</label>
      <input
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>
  )
}

interface CustomComboboxProps {
  labelElement: React.ReactNode
  options: { value: string; label: string }[]
  value?: string
  onChange: (value: string) => void
}

export const CustomCombobox: React.FC<CustomComboboxProps> = ({
  labelElement,
  options,
  value,
  onChange,
}) => {
  const [query, setQuery] = React.useState<string>('')
  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        )
  return (
    <div>
      <label>{labelElement}</label>
      <Combobox
        value={value}
        onChange={(value) => value !== null && onChange(value)}
      >
        <ComboboxInput
          displayValue={(option: { value: string; label: string }) =>
            option.label
          }
          onChange={(e) => setQuery(e.target.value)}
        />
        <ComboboxOptions anchor="bottom" className="empty:hidden">
          {filteredOptions.map((option) => (
            <ComboboxOption key={option.value} value={option.value}>
              {option.label}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </div>
  )
}
