export type Success<T> = {
  type: 'Success'
  value: T
}

export type Failure<E> = {
  type: 'Failure'
  error: E
}

export type Result<T, E> = Success<T> | Failure<E>

export function success<T>(value: T): Success<T> {
  return { type: 'Success', value }
}

export function failure<E>(error: E): Failure<E> {
  return { type: 'Failure', error }
}

export function mapError<T, E1 extends string, E2>(
  result: Result<T, E1>,
  errorMap: { [K in E1]: E2 }
): Result<T, E2> {
  if (result.type === 'Success') {
    return result
  } else {
    return failure(errorMap[result.error])
  }
}
export function mapValue<T1, T2, E>(
  result: Result<T1, E>,
  f: (t: T1) => T2
): Result<T2, E> {
  if (result.type === 'Success') {
    return success(f(result.value))
  } else {
    return result
  }
}
