export const resources = {
  en: {
    translation: {
      SignIn_Email: 'E-mail',
      SignIn_ユーザ名: 'Username',
      SignIn_パスワード: 'Password',
      SignIn_ログイン: 'Login',
      SignIn_新規登録はこちら: 'Register',
    },
  },
  ja: {
    translation: {
      SignIn_Email: 'E-mail',
      SignIn_ユーザ名: 'ユーザ名',
      SignIn_パスワード: 'パスワード',
      SignIn_ログイン: 'ログイン',
      SignIn_新規登録はこちら: '新規登録はこちら',
    },
  },
}
