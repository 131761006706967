import { AirlineRegistrationRequest } from '../../entity/AirlineRegistrationRequest'
import { ForwarderRegistrationRequest } from '../../entity/ForwarderRegistrationRequest'
import { Result, failure, success } from '../../entity/Result'
import { ShipperRegistrationRequest } from '../../entity/ShipperRegistrationRequest'
import { AuthnRepository } from '../repository/AuthnRepository'
import {
  AirlineRegistrationError,
  AuthzUsecase,
  ForwarderRegistrationError,
  ShipperRegistrationError,
  SignInError,
} from './AuthzUsecase'

export class AuthzUsecaseImpl implements AuthzUsecase {
  constructor(private authnRepository: AuthnRepository) {}
  async signIn(
    email: string,
    password: string
  ): Promise<Result<void, SignInError>> {
    const response = await this.authnRepository.apiv2.auth.signIn.$post({
      json: { email, password },
    })
    if (response.status === 401) {
      return failure('InvalidEmailOrPassword')
    } else {
      return success(void 0)
    }
  }

  async requestAirlineRegistration(
    params: AirlineRegistrationRequest
  ): Promise<Result<void, AirlineRegistrationError>> {
    const response =
      await this.authnRepository.apiv2.auth.register.airline.$post({
        json: params,
      })

    if (response.status === 400) {
      const body = await response.json()

      switch (body.errorCode) {
        case 'AlreadyRegisteredAirport':
          return failure('AlreadyRegisteredAirport')
        case 'AlreadyRegisteredEmail':
          return failure('InvalidEmail')
        case 'AlreadyRegisteredIataOrCassNumber':
          return failure('AlreadyUsedIataOrCassNumber')
        case 'InternalServerError':
          return failure('InternalServerError')
      }
    } else {
      return success(void 0)
    }
  }
  async requestForwarderRegistration(
    params: ForwarderRegistrationRequest
  ): Promise<Result<void, ForwarderRegistrationError>> {
    const response =
      await this.authnRepository.apiv2.auth.register.forwarder.$post({
        json: params,
      })

    if (response.status === 400) {
      const body = await response.json()
      switch (body.errorCode) {
        case 'AlreadyRegisteredAirport':
          return failure('AlreadyRegisteredAirport')
        case 'AlreadyRegisteredEmail':
          return failure('InvalidEmail')
        case 'AlreadyRegisteredIataOrCassNumber':
          return failure('AlreadyUsedIataOrCassNumber')
        case 'InternalServerError':
          return failure('InternalServerError')
      }
    } else {
      return success(void 0)
    }
  }
  async requestShipperRegistration(
    params: ShipperRegistrationRequest
  ): Promise<Result<void, ShipperRegistrationError>> {
    const response =
      await this.authnRepository.apiv2.auth.register.shipper.$post({
        json: params,
      })

    if (response.status === 400) {
      const body = await response.json()
      switch (body.errorCode) {
        case 'AlreadyRegisteredEmail':
          return failure('InvalidEmail')
        case 'AlreadyRegisteredShipperCode':
          return failure('AlreadyUsedShipperCode')
        case 'InternalServerError':
          return failure('InternalServerError')
      }
    } else {
      return success(void 0)
    }
  }
}
